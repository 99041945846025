// import React from 'react';

// interface Message {
//   content: string;
//   isUser: boolean;
// }

// interface ChatHistoryProps {
//   messages: Message[];
// }

// const ChatHistory: React.FC<ChatHistoryProps> = ({ messages }) => {
//   return (
//     <div className="chat-history">
//       {messages.map((message, index) => (
//         <div key={index} className={`message ${message.isUser ? 'user' : 'ai'}`}>
//           {message.content}
//         </div>
//       ))}
//     </div>
//   );
// };

// export default ChatHistory;




// import React from 'react';
// import { Image as ImageIcon } from 'lucide-react';

// interface Message {
//   content: string;
//   isUser: boolean;
// }

// interface ChatHistoryProps {
//   messages: Message[];
// }

// const MessageContent: React.FC<{ content: string }> = ({ content }) => {
//   // Regular expression to match URLs
//   const urlRegex = /(https?:\/\/[^\s]+)/g;
//   const parts = content.split(urlRegex);

//   return (
//     <>
//       {parts.map((part, index) => {
//         if (part.match(urlRegex)) {
//           return (
//             <a
//               key={index}
//               href={part}
//               target="_blank"
//               rel="noopener noreferrer"
//               className="inline-flex items-center mx-1 p-1 rounded hover:bg-gray-100"
//               title="Click to open image"
//             >
//               <ImageIcon className="w-5 h-5" />
//             </a>
//           );
//         }
//         return <span key={index}>{part}</span>;
//       })}
//     </>
//   );
// };

// const ChatHistory: React.FC<ChatHistoryProps> = ({ messages }) => {
//   return (
//     <div className="chat-history">
//       {messages.map((message, index) => (
//         <div key={index} className={`message ${message.isUser ? 'user' : 'ai'}`}>
//           <MessageContent content={message.content} />
//         </div>
//       ))}
//     </div>
//   );
// };

// export default ChatHistory;


// import React, { useEffect, useRef } from 'react';
// import { Image as ImageIcon } from 'lucide-react';

// interface Message {
//   content: string;
//   isUser: boolean;
// }

// interface ChatHistoryProps {
//   messages: Message[];
// }

// const MessageContent: React.FC<{ content: string }> = ({ content }) => {
//   const urlRegex = /(https?:\/\/[^\s]+)/g;
//   const parts = content.split(urlRegex);

//   return (
//     <>
//       {parts.map((part, index) => {
//         if (part.match(urlRegex)) {
//           return (
//             <a
//               key={index}
//               href={part}
//               target="_blank"
//               rel="noopener noreferrer"
//               className="inline-flex items-center mx-1 p-1 rounded hover:bg-gray-100"
//               title="Click to open image"
//             >
//               <ImageIcon className="w-5 h-5" />
//             </a>
//           );
//         }
//         return  part ? <span key={index}>{part}</span> : '';
//       })}
//     </>
//   );
// };



// const ChatHistory: React.FC<ChatHistoryProps> = ({ messages }) => {
//   const chatEndRef = useRef<HTMLDivElement | null>(null);

//   useEffect(() => {
//     // Scroll to the bottom when messages change
//     chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
//   }, [messages]);

//   return (
//     <div className="chat-history h-full">
//       {messages.map((message, index) => (
//         <div key={index} className={`message ${message.isUser ? 'user' : 'ai'}`}>
//           <MessageContent content={message.content} />
//         </div>
//       ))}
//       {/* This div acts as a target to scroll into view */}
//       <div ref={chatEndRef} />
//     </div>
//   );
// };

// export default ChatHistory;






import React, { useEffect, useRef } from 'react';
import { Image as ImageIcon, User, Bot } from 'lucide-react';



interface Message {
  content: string;
  isUser: boolean;
}

interface ChatHistoryProps {
  messages: Message[];
}

const MessageContent: React.FC<{ content: string }> = ({ content }) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const parts = content?.split(urlRegex);

  return (
    <pre className="whitespace-pre-wrap font-sans text-xs sm:text-sm">
      {parts.map((part, index) => {
        if (part.match(urlRegex)) {
          return (
            <a
              key={index}
              href={part}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center mx-1 p-1 rounded hover:bg-gray-100"
              title="Click to open image"
            >
              <ImageIcon className="w-4 h-4 mr-1 cursor-pointer" />
            </a>
          );
        }
        return part ? <span key={index}>{part}</span> : '';
      })}
    </pre>
  );
};



const ChatHistory: React.FC<ChatHistoryProps> = ({ messages }) => {
  const chatEndRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <div className="flex flex-col p-1">
      {messages.map((message, index) => (
        <div
          key={index}
          className={`flex ${message.isUser ? 'justify-end' : 'justify-start'}`}
        >
          <div
            className={`flex max-w-[80%] ${
              message.isUser ? 'flex-row-reverse' : 'flex-row'
            }`}
          >
            <div
              className={`flex items-start space-x-2 ${
                message.isUser ? 'flex-row-reverse space-x-reverse' : 'flex-row'
              }`}
            >
              <div
                className={`min-w-8 min-h-8 rounded-full flex items-center justify-center ${
                  message.isUser ? 'bg-blue-100' : 'bg-gray-100'
                }`}
              >
                {message.isUser ? (
                  <User className="sm:min-w-5 sm:min-h-5 min-w-3.5 min-h-3.5 sm:max-w-5 sm:max-h-5 max-w-3.5 max-h-3.5 text-blue-600" />
                ) : (
                   <Bot className="sm:min-w-5 sm:min-h-5 min-w-3.5 min-h-3.5 sm:max-w-5 sm:max-h-5 max-w-3.5 max-h-3.5 text-gray-600" />

                )}
              </div>
              
              <div
                className={`sm:p-3 p-1 rounded-lg m-1 ${
                  message.isUser
                    ? 'bg-blue-600 text-white rounded-tr-none'
                    : 'bg-gray-100 text-gray-900 rounded-tl-none'
                }`}
              >
                <MessageContent content={message.content} />
              </div>
            </div>
          </div>
        </div>
      ))}
      <div ref={chatEndRef} className="h-4" />
    </div>
  );
};

export default ChatHistory;