import React, { useState, useEffect, useRef,useCallback } from 'react';
import ChatHistory from './ChatHistory';
import ChatInput from './ChatInput';
import { v4 as uuidv4 } from 'uuid';

interface Message {
  content: string;
  isUser: boolean;
}

interface ChatInterfaceProps {
  logoContainerRef: any;
}

const ChatInterface: React.FC<ChatInterfaceProps> = ({ logoContainerRef }) => {
  console.log("  ChatInterface loaded")
  const [messages, setMessages] = useState<Message[]>([]);
  const chatEndRef = useRef<HTMLDivElement>(null);
  const ChatInputRef = useRef<HTMLDivElement>(null);
 
  const [uuid, setUuid] = useState('');
  const [chatHeight, setChatHeight] = useState('80vh'); 

  useEffect(() => {
    const calculateChatHeight = () => {

        const headerHeight = logoContainerRef.current ? logoContainerRef.current.offsetHeight : 0;
        const footerHeight = ChatInputRef.current ? ChatInputRef.current.offsetHeight : 0;
        const remainingHeight = `calc(100vh - ${(window.innerWidth < 640 ? headerHeight : 0) + footerHeight}px)`;
        setChatHeight(remainingHeight);
      
    };

    // Calculate height on mount and on resize
    calculateChatHeight();
    window.addEventListener('resize', calculateChatHeight);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('resize', calculateChatHeight);
    };
  }, []);


const handleSendMessage = useCallback(
  async (content: string) => {
    const userMessage: Message = { content, isUser: true };
    setMessages((prevMessages) => [...prevMessages, userMessage]);

    try {
      console.log("process.env.REACT_APP_SOLAR_GENIE_BACKEND_URL 2",process.env.REACT_APP_SOLAR_GENIE_BACKEND_URL)

      const response = await fetch(`${process.env.REACT_APP_SOLAR_GENIE_BACKEND_URL}/chat`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message: content, chat_id: uuid }),
      });

      if (!response.body) {
        throw new Error("Readable stream not supported.");
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value);
        const messages = chunk.trim().split("\n").map((line) => line.replace(/^data: /, "").trim());

        for (const message of messages) {
          if (message && message !== '') {
            const parsed = JSON.parse(message);
            const aiMessage: Message = { content: parsed.content, isUser: false };
            setMessages((prevMessages) => [...prevMessages, aiMessage]);
          }
        }
      }

      reader.releaseLock();
    } catch (error) {
      console.error('Error:', error);
    }
  },
  [uuid] // Add dependencies here
);



const WelcomeMessage = () => {
  const messages = [
    "Hello! I am Solar Genie, your solar expert",
    "How can I help you ?"
  ];
  const [displayedMessages, setDisplayedMessages] = useState<string[]>(['', '', '']);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);


  useEffect(() => {
    if (currentMessageIndex >= messages.length) return;

    const interval = setInterval(() => {
      if (charIndex < messages[currentMessageIndex].length) {
        setDisplayedMessages(prev => {
          const newMessages = [...prev];
          newMessages[currentMessageIndex] = messages[currentMessageIndex].slice(0, charIndex + 1);
          return newMessages;
        });
        setCharIndex(prev => prev + 1);
      } else {
        clearInterval(interval);
        setTimeout(() => {
          setCurrentMessageIndex(prev => prev + 1);
          setCharIndex(0);
        }, 500);
      }
    }, 50);

    return () => clearInterval(interval);
  }, [currentMessageIndex, charIndex]);

  return (
    <div className="text-center h-32"> {/* Fixed height container */}
      <h1 className="sm:text-4xl text-2xl font-semibold text-black mb-4">
        {displayedMessages[0]}
      </h1>
      <p className="sm:text-4xl text-2xl font-semibold text-orange-400 h-16"> {/* Fixed height for second message */}
        {displayedMessages[1]}
      </p>
    </div>
  );
};


  // Auto-scroll to the bottom whenever messages change
  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  useEffect(() => {
    const newUuid = uuidv4();
    console.log("##uuid 1",newUuid)
    setUuid(newUuid);
  }, []);


 const WelcomeComponent = <div className="w-full h-full flex flex-col">
   <div className="h-full flex flex-col items-center justify-center space-y-8">
     <WelcomeMessage />
     <div className="">
       <ChatInput onSendMessage={handleSendMessage} />
     </div>
   </div>
</div>


  return (
    <>
      {messages.length === 0 ? 
      (
        WelcomeComponent
      ) 
      : 
      (
        <div className=" w-full flex flex-col"
        style={{height: `calc(100vh - ${window.innerWidth < 640 ? logoContainerRef?.current?.clientHeight : 0}px)`}}>
          <div className='overflow-auto' style={{height:chatHeight}}>
            <ChatHistory messages={messages} />
            <div ref={chatEndRef} /> {/* Ensures auto-scroll targets this div */}
          </div>
          <div className='h-fit sm:flex items-end' 
          ref={ChatInputRef}>
            <ChatInput onSendMessage={handleSendMessage} isChatStarted={messages?.length !== 0}/>
          </div>
    
        </div>
      )
      }
    </>
  );



};

export default ChatInterface;
