
// import React, { useState, useRef } from 'react';
// import { Upload, FileIcon, Image as ImageIcon  } from 'lucide-react';

// interface ChatInputProps {
//   onSendMessage: (message: string) => void;
//   disabled?: boolean;
// }

// const ChatInput = ({ onSendMessage, disabled }: ChatInputProps) => {
//   const [input, setInput] = useState('');
//   const [isUploading, setIsUploading] = useState(false);
//   const [selectedFile, setSelectedFile] = useState<File | null>(null);
//   const fileInputRef = useRef<HTMLInputElement>(null);

//   const uploadImage = async (file: File) => {
//     setIsUploading(true);
//     try {
//       const formData = new FormData();
//       formData.append('file', file);

//       const response = await fetch('https://marketplace-api-stg-gcp.aerem.co/app/catalogue/uploadProductImage', {
//         method: 'POST',
//         headers: {
//           'accept': 'application/json, text/plain, */*',
//           'accept-language': 'en-GB,en-US;q=0.9,en;q=0.8',
//           'authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjEsInVzZXJSb2xlSWQiOjEsInZlbmRvcklkIjpudWxsLCJ2ZW5kb3JzTGlzdCI6W251bGxdLCJ1c2VyVHlwZUlkIjoyLCJpYXQiOjE3MzA2MzExOTIsImV4cCI6MTczMDkzMTE5Mn0.JJxThyMoSMrdoMTyKSHGHgAgQ20VukD6lqre32tlTH4',
//         },
//         body: formData
//       });

//       if (!response.ok) {
//         throw new Error('Upload failed');
//       }

//       const data = await response.json();
//       return data.url; // Adjust based on actual API response structure
//     } catch (error) {
//       console.error('Error uploading image:', error);
//       throw error;
//     } finally {
//       setIsUploading(false);
//     }
//   };

//   const handleSubmit = async (e: React.FormEvent) => {
//     e.preventDefault();
//     if ((input.trim() || selectedFile) && !disabled && !isUploading) {
//       try {
//         let finalMessage = input.trim();
        
//         if (selectedFile) {
//           const imageUrl = await uploadImage(selectedFile);
//           finalMessage = finalMessage ? `${finalMessage} ${imageUrl}` : imageUrl;
//           setSelectedFile(null);
//         }

//         onSendMessage(finalMessage);
//         setInput('');
//         if (fileInputRef.current) {
//           fileInputRef.current.value = '';
//         }
//       } catch (error) {
//         console.error('Error sending message:', error);
//         // Handle error appropriately
//       }
//     }
//   };

//   const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const file = e.target.files?.[0];
//     if (file) {
//       if (file.type.startsWith('image/')) {
//         setSelectedFile(file);
//       } else {
//         alert('Please select an image file');
//         e.target.value = '';
//       }
//     }
//   };

//   return (
//     <form onSubmit={handleSubmit} className="flex gap-2 w-full">
//       <div className="flex w-full items-center gap-2 border-2 border-red-500 bg-red h-[200px]">
//         <input
//           type="text"
//           value={input}
//           onChange={(e) => setInput(e.target.value)}
//           disabled={disabled || isUploading}
//           placeholder={
//             isUploading ? "Uploading..." :
//             disabled ? "Waiting for response..." : 
//             "Type a message..."
//           }
//           className="flex-1  p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:bg-gray-100"
//         />
//         <input
//           type="file"
//           ref={fileInputRef}
//           onChange={handleFileSelect}
//           accept="image/*"
//           className=""
//         />
//         {/* <button
//           type="button"
//           onClick={() => fileInputRef.current?.click()}
//           disabled={disabled || isUploading}
//           className="p-2 text-gray-500 hover:text-blue-500 disabled:text-gray-300 disabled:cursor-not-allowed ml-2 mr-2"
//         >
//           <Upload className="w-6 h-6" />
//         </button> */}
     
//       <button
//         type="submit"
//         disabled={disabled || isUploading || (!input.trim() && !selectedFile)}
//         className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
//       >
//         {isUploading ? 'Uploading...' : 'Send'}
//       </button>
//       </div>
//     </form>
//   );
// };

// export default ChatInput;





// import React, { useState, useRef } from 'react';
// import { Paperclip, Send } from 'lucide-react';

// interface ChatInputProps {
//   onSendMessage: (message: string) => void;
//   disabled?: boolean;
// }

// const ChatInput: React.FC<ChatInputProps> = ({ onSendMessage, disabled }) => {
//   const [input, setInput] = useState('');
//   const textareaRef = useRef<HTMLTextAreaElement>(null);

//   const adjustTextareaHeight = () => {
//     const textarea = textareaRef.current;
//     if (textarea) {
//       textarea.style.height = 'auto';
//       textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`;
//     }
//   };

//   const handleSubmit = (e: React.FormEvent) => {
//     e.preventDefault();
//     if (input.trim() && !disabled) {
//       onSendMessage(input.trim());
//       setInput('');
//       if (textareaRef.current) {
//         textareaRef.current.style.height = 'auto';
//       }
//     }
//   };

//   const handleKeyDown = (e: React.KeyboardEvent) => {
//     if (e.key === 'Enter' && !e.shiftKey) {
//       e.preventDefault();
//       handleSubmit(e);
//     }
//   };

//   return (
//     <div className="fixed bottom-0 left-0 w-full bg-gradient-to-t from-black to-transparent pb-8 pt-6">
//       <div className="mx-auto max-w-3xl px-4">
//         <form 
//           onSubmit={handleSubmit}
//           className="relative flex items-end bg-gray-700 rounded-xl shadow-xl"
//         >
//           <button
//             type="button"
//             className="absolute bottom-3 left-3 p-1 text-gray-400 hover:text-gray-300 transition-colors"
//           >
//             <Paperclip className="w-5 h-5" />
//           </button>
          
//           <textarea
//             ref={textareaRef}
//             value={input}
//             onChange={(e) => {
//               setInput(e.target.value);
//               adjustTextareaHeight();
//             }}
//             onKeyDown={handleKeyDown}
//             placeholder="Message Solar Genie"
//             rows={1}
//             disabled={disabled}
//             className="w-full bg-transparent text-white placeholder-gray-400 pl-12 pr-12 py-3 resize-none overflow-hidden focus:outline-none focus:ring-0 border-0"
//             style={{ maxHeight: '200px' }}
//           />
          
//           <button
//             type="submit"
//             disabled={!input.trim() || disabled}
//             className={`absolute bottom-3 right-3 p-1 transition-colors ${
//               input.trim() && !disabled
//                 ? 'text-white hover:text-gray-300'
//                 : 'text-gray-600'
//             }`}
//           >
//             <Send className="w-5 h-5" />
//           </button>
//         </form>
        
//         <div className="text-center mt-2">
//           <p className="text-gray-500 text-xs">
//             Solar Genie can make mistales.
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ChatInput;






// import React, { useState, useRef, useEffect } from 'react';
// import { Paperclip, Send } from 'lucide-react';

// interface ChatInputProps {
//   onSendMessage: (message: string) => void;
//   disabled?: boolean;
// }

// const ChatInput: React.FC<ChatInputProps> = ({ onSendMessage, disabled }) => {
//   const [input, setInput] = useState('');
//   const [isUploading, setIsUploading] = useState(false);
//   const [selectedFile, setSelectedFile] = useState<File | null>(null);
//   const fileInputRef = useRef<HTMLInputElement>(null);
//   const textareaRef = useRef<HTMLTextAreaElement>(null);

//   const adjustTextareaHeight = () => {
//     const textarea = textareaRef.current;
//     if (textarea) {
//       textarea.style.height = 'auto';
//       textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`;
//     }
//   };

//   const uploadImage = async (file: File) => {
//     setIsUploading(true);
//     try {
//       const formData = new FormData();
//       formData.append('file', file);

//       const response = await fetch('https://marketplace-api-stg-gcp.aerem.co/app/catalogue/uploadProductImage', {
//         method: 'POST',
//         headers: {
//           'accept': 'application/json, text/plain, */*',
//           'accept-language': 'en-GB,en-US;q=0.9,en;q=0.8',
//           'authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjEsInVzZXJSb2xlSWQiOjEsInZlbmRvcklkIjpudWxsLCJ2ZW5kb3JzTGlzdCI6W251bGxdLCJ1c2VyVHlwZUlkIjoyLCJpYXQiOjE3MzA2MzExOTIsImV4cCI6MTczMDkzMTE5Mn0.JJxThyMoSMrdoMTyKSHGHgAgQ20VukD6lqre32tlTH4',
//         },
//         body: formData
//       });

//       if (!response.ok) {
//         throw new Error('Upload failed');
//       }

//       const data = await response.json();
//       return data.url;
//     } catch (error) {
//       console.error('Error uploading image:', error);
//       throw error;
//     } finally {
//       setIsUploading(false);
//     }
//   };

//   const handleSubmit = async (e: React.FormEvent) => {
//     e.preventDefault();
//     if ((input.trim() || selectedFile) && !disabled && !isUploading) {
//       try {
//         let finalMessage = input.trim();
        
//         if (selectedFile) {
//           const imageUrl = await uploadImage(selectedFile);
//           finalMessage = finalMessage ? `${finalMessage} ${imageUrl}` : imageUrl;
//           setSelectedFile(null);
//         }

//         onSendMessage(finalMessage);
//         setInput('');
//         if (fileInputRef.current) {
//           fileInputRef.current.value = '';
//         }
//         if (textareaRef.current) {
//           textareaRef.current.style.height = 'auto';
//         }
//       } catch (error) {
//         console.error('Error sending message:', error);
//         // Handle error appropriately
//       }
//     }
//   };

//   const handleKeyDown = (e: React.KeyboardEvent) => {
//     if (e.key === 'Enter' && !e.shiftKey) {
//       e.preventDefault();
//       handleSubmit(e);
//     }
//   };

//   const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const file = e.target.files?.[0];
//     if (file) {
//       if (file.type.startsWith('image/')) {
//         setSelectedFile(file);
//       } else {
//         alert('Please select an image file');
//         e.target.value = '';
//       }
//     }
//   };

//   useEffect(() => {
//     const welcomeUserMessage = 'Hi'
//     onSendMessage(welcomeUserMessage);
//   }, []);

//   return (
//     <div className="fixed bottom-0 left-0 w-full bg-gradient-to- to-transparent pb-4 pt-6">
//       <div className="mx-auto max-w-3xl px-4">
//       {selectedFile && (
//           <div className="mt-2 text-blue-500 mb-2 text-sm flex items-center gap-2">
//             <Paperclip className="w-4 h-4" />
//             <span>{selectedFile.name}</span>
//           </div>
//         )}
//         <form onSubmit={handleSubmit} className="relative flex items-end bg-gray-700 rounded-xl shadow-xl">
//           <input
//             type="file"
//             ref={fileInputRef}
//             onChange={handleFileSelect}
//             accept="image/*"
//             className="hidden"
//           />
          
//           <button
//             type="button"
//             onClick={() => fileInputRef.current?.click()}
//             disabled={disabled || isUploading}
//             className="absolute bottom-3 left-3 p-1 text-gray-400 hover:text-gray-300 transition-colors disabled:text-gray-600 disabled:cursor-not-allowed"
//           >
//             <Paperclip className="w-5 h-5" />
//           </button>

//           <textarea
//             ref={textareaRef}
//             value={input}
//             onChange={(e) => {
//               setInput(e.target.value);
//               adjustTextareaHeight();
//             }}
//             onKeyDown={handleKeyDown}
//             disabled={disabled || isUploading}
//             placeholder={
//               isUploading ? "Uploading..." :
//               disabled ? "Waiting for response..." : 
//               "Message Solar Genie"
//             }
//             rows={1}
//             className="w-full bg-transparent text-white placeholder-gray-400 pl-12 pr-12 py-3 resize-none overflow-hidden focus:outline-none focus:ring-0 border-0"
//             style={{ maxHeight: '200px' }}
//           />

//           <button
//             type="submit"
//             disabled={disabled || isUploading || (!input.trim() && !selectedFile)}
//             className={`absolute bottom-3 right-3 p-1 transition-colors ${
//               (input.trim() || selectedFile) && !disabled && !isUploading
//                 ? 'text-white hover:text-gray-300'
//                 : 'text-gray-600'
//             }`}
//           >
//             <Send className="w-5 h-5" />
//           </button>
//         </form>


        
//         <div className="text-center mt-2">
//           <p className="text-gray-500 text-xs">
//             Solar Genie can make mistakes. Check important info.
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ChatInput;



import React, { useState, useRef, useEffect } from 'react';
import { Paperclip, Send } from 'lucide-react';

interface ChatInputProps {
  onSendMessage: (message: string) => void;
  disabled?: boolean;
  isChatStarted?: boolean;
}

const ChatInput: React.FC<ChatInputProps> = ({ onSendMessage, disabled, isChatStarted }) => {
  const [input, setInput] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  // Add suggested questions
  const suggestedQuestions = [
    "What solar panel size do I need?",
    "How much can I save with solar?",
    "What's the installation process?",
    "Are there any government rebates?",
  ];

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`;
    }
  };

  const uploadImage = async (file: File) => {
    setIsUploading(true);
    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await fetch(`${process.env.REACT_APP_SOLAR_GENIE_BACKEND_URL}/uploadfile`, {
        method: 'POST',
        headers: {
          'accept': 'application/json, text/plain, */*',
          'accept-language': 'en-GB,en-US;q=0.9,en;q=0.8',
        },
        body: formData
      });

      if (!response.ok) {
        throw new Error('Upload failed');
      }

      const data = await response.json();
      return data.url;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    } finally {
      setIsUploading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if ((input.trim() || selectedFile) && !disabled && !isUploading) {
      try {
        let finalMessage = input.trim();
        
        if (selectedFile) {
          const imageUrl = await uploadImage(selectedFile);
          finalMessage = finalMessage ? `${finalMessage} ${imageUrl}` : imageUrl;
          setSelectedFile(null);
        }

        onSendMessage(finalMessage);
        setInput('');
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
        if (textareaRef.current) {
          textareaRef.current.style.height = 'auto';
        }
      } catch (error) {
        console.error('Error sending message:', error);
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.type.startsWith('image/')) {
        setSelectedFile(file);
      } else {
        alert('Please select an image file');
        e.target.value = '';
      }
    }
  };

  const handleSuggestedQuestionClick = (question: string) => {
    setInput(question);
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  };

  // useEffect(() => {
  //   const welcomeUserMessage = 'Hi'
  //   onSendMessage(welcomeUserMessage);
  // }, []);

  return (
    <div className=" w-full ">
      <div className="mx-auto max-w-3xl sm:px-6 pb-2">
        {/* Suggested Questions */}
        <div className="mb-4 flex flex-wrap sm:gap-2 gap-1.5 items-start">
          {!isChatStarted && suggestedQuestions.map((question, index) => (
            <button
              key={index}
              onClick={() => handleSuggestedQuestionClick(question)}
              className="sm:px-3 sm:py-1.5 px-2.5 py-1 bg-gray-800 text-gray-300 rounded-full text-[0.6rem] sm:text-sm hover:bg-gray-700 transition-colors"
            >
              {question}
            </button>
          ))}
        </div>

        {selectedFile && (
          <div className="mt-2 text-blue-500 mb-2 text-sm flex items-center gap-2">
            <Paperclip className="w-4 h-4" />
            <span>{selectedFile.name}</span>
          </div>
        )}
        
        <form onSubmit={handleSubmit} className="relative flex items-end bg-gray-700 rounded-xl shadow-xl">
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileSelect}
            accept="image/*"
            className="hidden"
          />
          
          <button
            type="button"
            onClick={() => fileInputRef.current?.click()}
            disabled={disabled || isUploading}
            className="absolute bottom-3 left-3 p-1 text-gray-400 hover:text-gray-300 transition-colors disabled:text-gray-600 disabled:cursor-not-allowed"
          >
            <Paperclip className="w-5 h-5" />
          </button>

          <textarea
            ref={textareaRef}
            value={input}
            onChange={(e) => {
              setInput(e.target.value);
              adjustTextareaHeight();
            }}
            onKeyDown={handleKeyDown}
            disabled={disabled || isUploading}
            placeholder={
              isUploading ? "Uploading..." :
              disabled ? "Waiting for response..." : 
              "Message Solar Genie"
            }
            rows={1}
            className="w-full bg-transparent text-white placeholder-gray-400 pl-12 pr-12 py-3 resize-none overflow-hidden focus:outline-none focus:ring-0 border-0"
            style={{ maxHeight: '200px' }}
          />

          <button
            type="submit"
            disabled={disabled || isUploading || (!input.trim() && !selectedFile)}
            className={`absolute bottom-3 right-3 p-1 transition-colors ${
              (input.trim() || selectedFile) && !disabled && !isUploading
                ? 'text-white hover:text-gray-300'
                : 'text-gray-600'
            }`}
          >
            <Send className="w-5 h-5" />
          </button>
        </form>

        <div className="text-center mt-2">
          <p className="text-gray-500 text-xs">
            Solar Genie can make mistakes. Check important info.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ChatInput;